:root {--primary-color: #AD1F25;--secondary-color: #ffffff;}

/* calculated for bottom footer */
main{min-height:100vh;min-height:calc(100vh - 437px);}

.text-underline{text-decoration: underline;}
.text-shadow{text-shadow:0px 1px 2px rgba(0,0,0,0.25);}
.navbar-toggler:focus{outline:none;box-shadow:none;}
.link-unstyled,
.link-unstyled:hover{text-decoration:none;}
.btn-span,
.btn-span:hover{pointer-events:none;}
.bg-dark-alpha-2 {background-color: rgba(0, 0, 0, 0.2) !important;}
.bg-dark-alpha-6 {background-color: rgba(0, 0, 0, 0.6) !important;}
label.invalid-input {color: var(--danger); font-size: 95%; margin-bottom: 0;}

/* navbar ===================================================================================================== */
.twt-navbar.twt-navbar-common{background:#fff;transition:0.5s all;min-height:90px;position:fixed;top:0;left:0;right:0;z-index:1022;border-bottom: 1px solid #DDDDDD;padding: 0;}
.twt-navbar .navbar-brand{padding: 0;margin: 0;}
.twt-navbar.twt-navbar-common.fixed{box-shadow: none;background:#fff;}
.twt-navbar.twt-navbar-common .navbar-nav .nav-item .nav-link,
.twt-navbar.twt-navbar-common.fixed .navbar-nav .nav-item .nav-link{padding: 33px 10px;font-size: 16px;font-weight: 500;line-height: 24px;color: #212529;transition:0.25s all;display: flex;align-items: center;}
.twt-navbar.twt-navbar-common .navbar-nav .nav-item:nth-last-child(1) .nav-link, .twt-navbar.twt-navbar-common.fixed .navbar-nav .nav-item:nth-last-child(1) .nav-link{padding-right: 0;}
.twt-navbar.twt-navbar-common .navbar-nav .nav-item.active>.nav-link, .twt-navbar.twt-navbar-common .navbar-nav .nav-item:hover .nav-link{color: var(--primary-color);}
.twt-navbar.twt-navbar-common button.navbar-toggler{padding: 0;margin: 0;}
.twt-navbar.twt-navbar-common button.navbar-toggler:active, .twt-navbar.twt-navbar-common button.navbar-toggler:focus,.twt-navbar.twt-navbar-common button.navbar-toggler:focus-visible, .twt-navbar.twt-navbar-common button.navbar-toggler:hover{box-shadow: none !important;}
.twt-navbar.twt-navbar-common button.navbar-toggler .navbar-dash{display:block;height:3px;width:30px;margin:6px;pointer-events:none;background: #000;}
.twt-navbar .dropdown-toggle::after{border: 0;content: "\f153";font-family: uicons-regular-rounded!important;font-size: 20px;line-height: 0;margin-left: 2px;width: 20px;height: 20px;display: flex;align-items: center;justify-content: center;color: #000;}
.twt-navbar.twt-navbar-common .navbar-nav .nav-item.active> .dropdown-toggle::after, .twt-navbar.twt-navbar-common .navbar-nav .nav-item:hover .dropdown-toggle::after{color: var(--primary-color);}
.twt-navbar.twt-navbar-common .dropdown-menu{border:0;border-radius:2px;background:none;padding:0;background:#fff;margin-top:0px!important;box-shadow:0 25px 15px rgba(0,0,0,0.1);width: 230px;}
.twt-navbar.twt-navbar-common .dropdown-menu .dropdown-item{transition:0.2s all;color:#212529;padding: 8px 10px;white-space: pre-wrap;font-size: 14px;line-height: 24px;}
.twt-navbar.twt-navbar-common .dropdown-menu .dropdown-item:hover{background:var(--primary-color);color:#fff;}
.twt-navbar.twt-navbar-common .brand-logo-img{height:70px;}
.twt-navbar .dropdown:has(.active) .dropdown-toggle, .twt-navbar .dropdown:has(.active) .dropdown-toggle::after{color: var(--primary-color) !important;}
.dropdown-item.active, .dropdown-item:active{background-color: var(--primary-color) !important;color: #fff !important;}
@media (max-width:991px){
  .twt-navbar.twt-navbar-common .dropdown-menu .dropdown-item:hover{background: transparent;color: var(--primary-color);}
  .twt-navbar.twt-navbar-common .navbar-nav .nav-item:hover .nav-link{background: transparent;}
  .twt-navbar.twt-navbar-common .slide{position:fixed;z-index:999;top:0;bottom:0;left:0;min-width:250px;width:270px;max-width:90%;background-color:#fff;transform:translate3d(-100%,0,0);transition:0.5s all cubic-bezier(0.165,0.84,0.44,1);border-right:1px solid transparent;overflow-y:auto;}
	.twt-navbar.twt-navbar-common .navbar-nav .nav-item .nav-link,
  .twt-navbar.twt-navbar-common.fixed .navbar-nav .nav-item .nav-link{padding:0.75rem 1rem;border-bottom: 1px solid #ddd;}
  .twt-navbar.twt-navbar-common .dropdown-menu{background:none;box-shadow:none;width: 100%;border-bottom: 1px solid #ddd;}
  .twt-navbar.twt-navbar-common .dropdown-menu .dropdown-item{color:#212529;padding-left:1.75rem; position: relative;}
  .nav-slide-open .twt-navbar.twt-navbar-common .slide{transform:translate3d(0,0,0);box-shadow:2px 0 5px rgba(0,0,0,0.3);opacity: 1;}
  .dropdown-item.active, .dropdown-item:active{background-color: transparent !important;color: var(--primary-color) !important;}
}
@media (max-width:575px){
  .twt-navbar.twt-navbar-common .dropdown-menu .dropdown-item{white-space:normal;}
  .twt-navbar.twt-navbar-common .brand-logo-img{height: 65px;}
}
@media (min-width:992px){
  .twt-navbar.twt-navbar-common .dropdown-menu.w-200px{min-width:200px;}
  .twt-navbar.twt-navbar-common .dropdown-left .dropdown-menu{left:auto;right:0;}
}


/* submenu css */
.twt-navbar.twt-navbar-common .dropdown-submenu{position:relative;}
.twt-navbar.twt-navbar-common .dropdown-submenu a:after{position:absolute;top:50%;right:6px;transform:translateY(-50%);border-top:0.3em solid transparent;border-right:0.3em solid transparent;border-bottom:0.3em solid transparent;}
.twt-navbar.twt-navbar-common .dropdown-submenu .dropdown-menu{top:0;left:100%;margin-left:0.1rem;margin-right:0.1rem;}
@media (max-width:991px){
    .twt-navbar.twt-navbar-common .dropdown-submenu .dropdown-menu{padding-left:1rem;}
}

/* submenu css */

/* hamburger on close */

.twt-navbar.twt-navbar-common button.navbar-toggler .navbar-dash{transition:0.2s all;}
.nav-slide-open .twt-navbar.twt-navbar-common button.navbar-toggler .navbar-dash:nth-child(2){opacity:0;}
.nav-slide-open .twt-navbar.twt-navbar-common button.navbar-toggler .navbar-dash:nth-child(1){transform:rotate(45deg) translate(5px,8px);}
.nav-slide-open .twt-navbar.twt-navbar-common button.navbar-toggler .navbar-dash:nth-child(3){transform:rotate(-45deg) translate(5px,-8px);}

/* hamburger on close */


.twt-navbar.twt-navbar-common .nav-bottom .navbar-nav .nav-item .nav-link{border-radius: 0;}

/* nav-shadow ===================================================================================================== */

.nav-shadow {background-color: var(--primary) !important; border-radius: 0;}

/* nav-shadow ===================================================================================================== */

/* navbar ===================================================================================================== */

/* footer ===================================================================================================== */

.footer{background-color:#fff;padding: 48px 0;}
.footer.footer-dark,
.footer.footer-dark a{color:#aaa;}
.footer.footer-dark a{transition:0.1s;}
.footer.footer-dark a:hover{color:#fff;}
.footer .brand-logo-img{margin-bottom: 20px;height: 100px;}

.footer .address-mrow{display: flex;flex-direction: column;gap: 10px 0;}
.footer .address-mrow .icon-text{display: flex;align-items: center;gap: 0 6px;width: fit-content;}
.footer .address-mrow .icon-text.location-text{align-items: flex-start;}
.footer .address-mrow .icon-text .icon{line-height: 0;width: 20px;height: 20px;font-size: 20px;color: #444444;}
.footer .address-mrow .icon-text.location-text .icon{padding-top: 4px;}
.footer .address-mrow .icon-text .text, .footer .address-mrow .icon-text .text > p{margin-bottom: 0;font-size: 16px;font-weight: 400;line-height: 24px;color: #444444;}
.footer .footer-heading-text{font-size: 20px;font-weight: 600;line-height: 28px;color: #212529;margin-bottom: 20px;text-transform: uppercase;margin-top: 30px;}
.footer .link-list{display: flex;flex-direction: column;gap: 10px 0;margin-bottom: 0;}
.footer .link-list li{width: fit-content;}
.footer .link-list .footer-link{display: flex;align-items: center;font-size: 16px;font-weight: 400;line-height: 24px;color: #444;transition: all 0.2s ease-in-out;}
.footer .link-list .footer-link:hover{color: var(--primary-color);}
.footer .link-list .footer-link .icon{line-height: 0;height: 20px;display: flex;align-items: center;justify-content: center;font-size: 16px;color: var(--primary-color);width: 0;opacity: 0;transition: all 0.2s ease-in-out;margin-right: 0;}
.footer .link-list .footer-link:hover .icon{opacity: 1;width: 20px;margin-right: 6px;}
.footer .socials{margin-top: 20px;border-top: 1px solid #ddd;padding-top: 20px;}
.footer .socials{display: flex;align-items: center;gap: 0 15px;}
.footer .socials .icn-img{height: 24px;width: 24px;}
/* .footer .footer-row{gap: 30px 0;} */
footer .copyright-footer{background: var(--primary-color);padding: 10px 0;}
footer .copyright-footer .inner-content{display: flex;align-items: center;justify-content: space-between;}
footer .copyright-footer .inner-content p{margin-bottom: 0;}
footer .copyright-footer .inner-content .copyright-footer-text{font-size: 16px;font-weight: 400;line-height: 24px;color: #fff;}
footer .copyright-footer .inner-content .copyright-footer-text.right-border{padding-right: 7px;margin-right: 7px;position: relative;}
footer .copyright-footer .inner-content .copyright-footer-text.right-border::before{content: "";position: absolute;right: -1px;top: 50%;transform: translateY(-50%);width: 1px;height: 14px;background: #fff;}
footer .copyright-footer .inner-content a{color: #fff;}

/* footer ===================================================================================================== */

/* owlcarousel default override ===================================================================================================== */

/* dots */

.owl-carousel {touch-action: manipulation; }
.owl-theme .owl-dots{position:absolute;left:0;bottom:0;width:100%;}
.owl-theme .owl-dots .owl-dot span{background-color:var(--theme-color, #3b5998);margin:5px;box-shadow: 0 0 1px 1px var(--theme-color, #3b5998);}
.owl-theme .owl-dots .owl-dot.active span{background-color:var(--white);width:30px;}

/* nav (prev-next) */
.owl-theme .owl-nav{margin:0;}
.owl-theme .owl-nav .owl-prev,
.owl-theme .owl-nav .owl-next{font-size:0;position:absolute;top:50%;transform:translateY(-50%);height:50px;width:50px;line-height:50px;padding:0;margin:0;background:#fff;color:#333;box-shadow:0 3px 15px rgba(0,0,0,0.3);}
.owl-theme .owl-nav .owl-prev:hover,
.owl-theme .owl-nav .owl-next:hover{background:#fafafa;color:#333;}
.owl-theme .owl-nav .owl-prev{left:0;}
.owl-theme .owl-nav .owl-next{right:0;}
.owl-theme .owl-nav .owl-prev:after,
.owl-theme .owl-nav .owl-next:after{content:"\f104";font-size:2rem;font-family:"Font Awesome 5 Free";font-weight:900;}
.owl-theme .owl-nav .owl-next:after{content:"\f105";}

/* item */
.main-banner-slider .banner-img{object-fit:cover;object-position:center;}

/* owlcarousel default override ===================================================================================================== */

/* important css Start */
*{padding:0; margin:0; box-sizing: border-box;}
body{overflow-x: hidden; padding:0; margin:0;} 
/* important css End */

/* mobile version display text */
@media(max-width: 991px){
  .mobile-view-none{display: flex;align-items: center;justify-content: center;min-height: 100vh;font-size: 25px;text-align: center;padding: 15px;}
}
/* mobile version display text */

/* Whatsapp Start */
.circle:before { -webkit-animation: ripple 2s linear infinite; animation: ripple 2s linear infinite; }
.circle:before, .circle:after { content: ""; display: block; position: absolute; top: 0; right: 0; bottom: 0; left: 0; border-radius: 50%; border: 11px solid #29a4af70; z-index: -1; }
.cover-a-tag { position: absolute; left: 0; right: 0; top: 0; bottom: 0; display: flex; align-items: center; justify-content: center; } 
.whatsapp-icon { position: fixed; bottom: 30px; z-index: 1050; background-color: #4caf50; height: 60px; right: 20px; width: 60px; border-radius: 50%; display: flex; align-items: center; justify-content: center; font-size: 15px; }
.whatsapp-right { right: 15px; left: unset; }
.whatsapp-left { left: 15px; right: unset; }
  
  @keyframes ripple {
    0% {
      opacity: 0.25;
    }
  
    100% {
      border-radius: 2em;
      opacity: 0;
      transform: scale(3);
    }
  }
  @media (max-width: 767px) {
    .whatsapp-icon { width: 50px; height: 50px; font-size: 14px; }
  }
  
/* Whatsapp End */

/* Loader css  */
.loader-showing{overflow: hidden;position: relative;}
.loader{width: 100%;height: 100vh;min-height: 100vh;display: flex;justify-content: center;align-items: center;position: fixed;top: 0;bottom: 0;left: 0;right: 0;background-color: #00000093;z-index: 9999;overflow: hidden !important;}
.loader--ring div {border-color: #fff transparent transparent transparent !important;}
.loader--ring {display: inline-block;position: relative;height: 45px;width: 45px;}
.loader--ring div {display: block;position: absolute;animation: loader--ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;border: 6px solid #fff;border-radius: 50%;box-sizing: border-box;margin: 6px;height: 51px;width: 51px;}
.loader--ring div:nth-child(1) {animation-delay: -0.45s;}
.loader--ring div:nth-child(2) {animation-delay: -0.3s;}
.loader--ring div:nth-child(3) {animation-delay: -0.15s;}
@keyframes loader--ring {
  0% {transform: rotate(0deg);}
  100% {transform: rotate(360deg);}
}

/* width */
::-webkit-scrollbar {width: 10px;height: 10px;}
/* Track */
::-webkit-scrollbar-track {background: #EEEEEE; border-radius: 100px;}
/* Handle */
::-webkit-scrollbar-thumb {background: var(--primary-color); border-radius: 100px;}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {background: var(--primary-color)}

/* Common CSS  */
.primary-arrow-btn{padding: 15px 30px;background: var(--primary-color);display: flex;align-items: center;justify-content: center;gap: 0 6px;width: fit-content;font-size: 16px;font-weight: 400;line-height: 24px;color: #fff;transition: all 0.2s ease-in-out;border: 0;}
.primary-arrow-btn.bg-theme-light{background: #FDE3E4;color: var(--primary-color);}
.primary-arrow-btn.bg-theme-light:hover{background: var(--primary-color);}
.primary-arrow-btn .btn-icon{width: 24px;height: 24px;line-height: 0;font-size: 20px;color: #fff;display: flex;align-items: center;justify-content: center;}
.primary-arrow-btn.bg-theme-light .btn-icon{color: var(--primary-color);}
.primary-arrow-btn.bg-theme-light:hover .btn-icon{color: #fff;}
.primary-arrow-btn:hover{background: #212529;color: #fff;}

.p-text{font-size: 16px;font-weight: 400;line-height: 24px;margin-bottom: 0;color: #444;}
.p-text-div{display: flex;flex-direction: column;gap: 15px 0;}

.sec-padding{padding: 48px 0;}

.common-heading{display: flex;flex-direction: column;align-items: center;justify-content: center;gap: 10px 0;margin-bottom: 34px;}
.heading-div{display: flex;flex-direction: column;gap: 10px 0;}
.common-sub-heading{font-size: 16px;font-weight: 500;line-height: 24px;color: var(--primary-color);text-transform: uppercase;margin-bottom: 0;}
.common-heading-text{font-size: 40px;font-weight: 600;line-height: 54px;color: #212529;margin-bottom: 0;text-transform: capitalize;}
.common-heading .common-heading-text{max-width: 750px;text-align: center;}
.common-heading.full-text .common-heading-text{max-width: 100%;}
.common-heading.small-letter .common-heading-text{text-transform: unset;}

.border-bottom{border-color: #DDDDDD;}
.w-lg-100{width: 100%;}

.bordered-input{border: 1px solid#DDDDDD;border-radius: 0;padding: 12px 16px;font-size: 16px;line-height: 24px;font-weight: 400;color: #212529;caret-color: var(--primary-color);height: 100%;}
.bordered-input::placeholder{color: rgb(33 37 41 / 40%);}
.bordered-input:focus{box-shadow: none;border-color: var(--primary-color);}

.col-right{padding-left: 15px;}
.col-left{padding-right: 15px;}

.main-navbar-wrapper{min-height: 91px !important;}

/* Main Banner Section */
.main-banner-section{background: #FFFFFF;padding: 80px 0;}
.main-banner-section .banner-row{gap: 30px 0;}
.main-banner-section .text-content .title{font-size: 50px;font-weight: 700;line-height: 72px;color: #212529;margin-bottom: 25px;}
.main-banner-section .text-content .sub-text{font-size: 20px;font-weight: 400;line-height: 28px;margin-bottom: 30px;color: #444444;}
.main-banner-section .text-content .btn-grp{display: flex;align-items: center;gap: 0 15px;}
.main-banner-section .img-col{display: flex;align-items: center;justify-content: center;}

/* Your Challenges Section */
.challenge-section{background: #F0F1F2;position: relative;padding: 48px 0;overflow: hidden;}
/* .challenge-section .common-heading-text{font-size: 29px;line-height: 48px;} */
.challenge-section .inner-card-row, .challenge-section .challenge-sec-row{gap: 20px 0;}
.challenges-card{padding: 20px;border-radius: 10px;background: #fff;box-shadow: 0px 0px 16px 0px #2125291A;height: 100%;display: flex;flex-direction: column;gap: 20px 0;position: relative;}
.challenges-card .icon-div{width: 100px;height: 100px;border-radius: 10px;background: #FEF2F3;display: flex;align-items: center;justify-content: center;padding: 23px;}
.challenge-section .challenges-card .icon-div .icon{filter: invert(11%) sepia(91%) saturate(3084%) hue-rotate(346deg) brightness(116%) contrast(92%);}
.challenges-card .text-content{display: flex;flex-direction: column;gap: 7px 0;}
.challenges-card .text-content .title{font-size: 20px;font-weight: 600;line-height: 28px;color: #212529;margin-bottom: 0;}
.challenge-section .line-bg, .background-sec .line-bg{position: absolute;left: 0;bottom: 0;opacity: 0.10;}
.challenge-section .heading-div{padding-top: 20px;}

/* How We Serve Section */
.hws-sec{position: static;background: #fff;}
.hws-sec .left-content{padding-top: 48px;height: calc(100vh - 90.25px);position: sticky;top: 90.25px;}
/* .hws-sec .right-col{scroll-snap-type: y mandatory;} */
.hws-sec .inner-content{width: 100%;background: #FEF2F3;padding: 50px 70px;min-height: calc(100vh - 90.25px);position: relative;}
.hws-sec .inner-content .title{font-size: 40px;font-weight: 600;line-height: 54px;text-align: center;color: #212529;margin-bottom: 27px;text-transform: capitalize;max-width: 600px;margin-left: auto;margin-right: auto;}
.hws-sec .inner-content .right-icon{position: absolute;left: 0;top: 119px;}
.hws-sec .inner-content:nth-child(1) .content{display: flex;align-items: flex-end;justify-content: center;}
.hws-sec .inner-content .cards{display: flex;flex-direction: column;}
.hws-sec .inner-content .cards.and-div{min-width: 100px;}
.hws-sec .inner-content:nth-child(1) > .title{margin-bottom: 151px  ;}
.hws-sec .inner-content .cards .card-imgs{margin-bottom: 20px;max-height: 150px;object-fit: contain;}
.hws-sec .inner-content .cards .title{font-size: 28px;font-weight: 600;line-height: 42px;text-align: center;color: #212529;margin-bottom: 0;}
.hws-sec .inner-content .consulting-lifecycle-img{margin: auto;display: flex;height: 567px;}
.hws-sec .inner-content:nth-child(3) .title{margin-bottom: 95px;}
.hws-sec .inner-content:nth-child(3) .content{display: flex;flex-direction: column;}
.hws-sec .inner-content:nth-child(3) .single-card{display: flex;align-items: center;gap: 0 15px;padding-bottom: 48px;border-bottom: 1px solid #DDDDDD;margin-bottom: 48px;}
.hws-sec .inner-content:nth-child(3) .single-card:nth-last-child(1){margin-bottom: 0;padding-bottom: 0;border-bottom: 0;}
.hws-sec .inner-content:nth-child(3) .single-card .number-div .number{font-size: 70px;font-weight: 600;line-height: 70px;color: var(--primary-color);margin-bottom: 0;min-width: 60px;}
.hws-sec .inner-content:nth-child(3) .single-card .text-conent{display: flex;flex-direction: column;gap: 10px 0;}
.hws-sec .inner-content:nth-child(3) .single-card .text-conent .name{font-size: 22px; font-weight: 600;line-height: 30px;color: #212529;margin-bottom: 0;}
.hws-sec .inner-content:nth-child(3) .single-card .text-conent .p-text{font-size: 20px;line-height: 28px;}
.hws-sec .dots{display: flex;flex-direction: column;gap: 15px 0;margin: 130px auto auto auto;width: fit-content;}
.hws-sec .dots .round-dot{width: 50px;height: 50px;border-radius: 100px;background: #E7E6E7;display: block;}
.hws-sec .dots .round-dot.active{background: var(--primary-color);}

/* Testimonials Section */
.testimonials-sec{padding: 48px 0 48px 0;background: #F0F1F2;position: relative;}
.testimonials-sec .swiper-slide {height: auto !important;}
.testimonials-sec .slider-content .inner-content{background: #FFFFFF;padding: 30px;border-radius: 10px;display: flex;flex-direction: column;gap: 20px 0;min-height: 176px;justify-content: center;align-items: center;margin: 0 7.5px;max-width: 945px;margin: auto;height: 100%;}
.testimonials-sec .slider-content .inner-content .p-text{text-align: center;}
.testimonials-sec .slider-content .inner-content .name{font-size: 16px;font-weight: 600;line-height: 24px;text-align: center;margin-bottom: 0;color: #212529;}

.swiper-pagination-fraction, .swiper-pagination-custom, .swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal{bottom: 0;}
/* .testimonials-sec .swiper{} */
.testimonials-sec .swiper .swiper-slide{padding-bottom: 32px;}
.testimonials-sec .swiper-pagination-bullet{width: 12px;height: 12px;margin: 0 6px;}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev, .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next{background: #212529;opacity: 0.5;}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev-prev, .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next-next{background: #212529;opacity: 0.3;}
.swiper-pagination-bullet-active{background: var(--primary-color);}

/* Breadcrumb Section */
.breadcrumbs-sec{position: relative;padding: 48px 0;background: #F0F1F2;}
.breadcrumbs-sec .page-name{font-size: 16px;font-weight: 500;line-height: 24px;color: var(--primary-color);margin-bottom: 10px;}
.breadcrumbs-sec .text{margin-bottom: 0;font-size: 32px;font-weight: 600;line-height: 48px;color: #212529;text-transform: capitalize;}
.breadcrumbs-sec .breadcrumb-line{position: absolute;right: 0;bottom: 0;}
.breadcrumbs-sec.text-singleline .text{display: -webkit-box;-webkit-line-clamp: 1;-webkit-box-orient: vertical;overflow: hidden;text-overflow: ellipsis;}

/* Why We do What We Do */
.why-we-sec .inner-content .quote-text{font-size: 20px;font-weight: 600;line-height: 31px;text-align: center;margin-top: 20px;margin-bottom: 0;color: #212529;}
.why-we-sec .inner-content{display: flex;flex-direction: column;gap: 34px 0;}
.why-we-sec .inner-content .values-div .bold-title{font-size: 20px;font-weight: 600;line-height: 28px;color: #212529;margin-bottom: 15px;}
.why-we-sec .card-row{display: grid;grid-template-columns: repeat(5,1fr);}
.why-we-sec .card-row .value-card{display: flex;flex-direction: column;padding: 0 20px 20px 20px;gap: 20px 0;align-items: center;justify-content: center;}
.why-we-sec .card-row .value-card{border: 1px solid #DDDDDD;}
.why-we-sec .card-row .value-card{border-right: 0;}
.why-we-sec .card-row .value-card:nth-last-child(1){border-right: 1px solid #DDDDDD;}
.why-we-sec .card-row .value-card .icon-div{width: 100px;height: 100px;background: #FEF2F3;display: flex;align-items: center;justify-content: center;border-radius: 0 0 10px 10px;}
.why-we-sec .card-row .value-card .icon-div .value-icon{height: 54px;width: 54px;}
.why-we-sec .card-row .value-card .value-title{margin-bottom: 0;font-size: 20px;font-weight: 600;line-height: 28px;text-align: center;color: #212529;}

/* Background Sec */
.background-sec{background: #F0F1F2;position: relative;}
.background-sec .inner-card-row{gap: 20px 0;justify-content: center;}
.background-sec .challenges-card .bold{font-weight: 600;color: #212529;}

/* Team Members Sec */
.team-member-sec .member-card{padding: 15px;box-shadow: 0px 0px 16px 0px #21252929;border-radius: 10px;background: #fff;display: flex;flex-direction: column;height: 100%;}
.team-member-sec .inner-row{gap: 20px 0;max-height: 754px;overflow-y: auto;padding: 10px 0;}
.team-member-sec .member-card .top-content{display: flex;align-items: center;gap: 0 10px;padding-bottom: 10px;margin-bottom: 10px;border-bottom: 1px solid #DDDDDD;}
.team-member-sec .member-card .top-content .member-img{width: 80px;height: 80px;object-fit: cover;border-radius: 7px;}
.team-member-sec .member-card .top-content .name-info{display: flex;flex-direction: column;gap: 6px 0;}
.team-member-sec .member-card .top-content .name-info .name{font-size: 18px;font-weight: 600;line-height: 26px;color: var(--primary-color);margin-bottom: 0;}
.team-member-sec .member-card .top-content .name-info .designation{font-size: 14px;font-weight: 400;line-height: 22px;color: #444444;margin-bottom: 0;}
.team-member-sec .member-card .bottom-content .info-content{display: flex;flex-direction: column;gap: 10px 0;}
.team-member-sec .member-card .bottom-content .experties-div{display: flex;flex-direction: column;}
.team-member-sec .member-card .bottom-content .title{font-size: 14px;font-weight: 600;line-height: 22px;color: #212529;margin-bottom: 4px;}
.team-member-sec .member-card .bottom-content .list{display: flex;flex-direction: column;margin-bottom: 0;}
.team-member-sec .member-card .bottom-content .list > li{font-size: 14px;font-weight: 400;line-height: 22px;margin-bottom: 0;color: #444;}
.team-member-sec .member-card .bottom-content .p-text{font-size:14px;line-height: 22px;}
.team-member-sec .member-card .bottom-content{display: flex;flex-direction: column;justify-content: space-between;gap: 10px 0;height: 100%;}
.team-member-sec .member-card .bottom-content .social-link-div{display: flex;gap: 0 10px;}
.team-member-sec .member-card .bottom-content .social-link-div .social-link{width: 28px;height: 28px;border-radius: 100px;background: #898789;display: flex;align-items: center;justify-content: center;}
.team-member-sec .member-card .bottom-content .social-link-div .social-link .icon{font-size: 14px;color: #fff;}

/* Why IBC Sec */
.why-ibc-sec .tab-sec{display: flex;flex-direction: column;align-items: center;justify-content: center;gap: 30px 0;}
.why-ibc-sec .tab-sec .nav{background: #E7E6E7;border-radius: 100px;padding: 10px;gap: 0 7px;}
.why-ibc-sec .tab-sec .nav .nav-item .nav-link{border-radius: 100px;padding: 7px 15px;background: transparent;font-size: 16px;font-weight: 600;line-height: 24px;color: #212529;}
.why-ibc-sec .tab-sec .nav .nav-item .nav-link.active{background: #fff;box-shadow: 0px 4px 4px 0px #0000001A;}
.why-ibc-sec .tab-sec .inner-card-row{justify-content: center;gap: 30px 0;}

.bottom-right-line{position: absolute;right: 0;bottom: 0;}
.top-left-line{position: absolute;left: 0;top: 0;}

/* Roles Sec */
.roles-sec .inner-content{display: flex;flex-direction: column;gap: 15px 0;}
.roles-sec .inner-content .subtitle{font-size: 18px;font-weight: 500;line-height: 26px;color: #444;margin-bottom: 0;}
.roles-sec .inner-content .role-cnt{display: flex;padding-left: 30px;flex-direction: column;gap: 15px 0;padding-bottom: 20px;margin-bottom: 5px;border-bottom: 1px solid #DDDDDD}
.roles-sec .inner-content .role-cnt .single-cnt{display: flex;gap: 0 10px;}
.roles-sec .inner-content .role-cnt .single-cnt .red-dot{width: 9px;min-width: 9px;height: 9px;border-radius: 100px;background: var(--primary-color);display: flex;align-items: center;margin-top: 7px;}
.roles-sec .inner-content .role-cnt .single-cnt .text{display: flex;flex-direction: column;gap: 5px 0;}
.roles-sec .inner-content .role-cnt .single-cnt .text .title{font-size: 18px;font-weight: 600;line-height: 26px;color: #000;margin-bottom: 0;}
.roles-sec .inner-content .mail-text{color: var(--primary-color);font-weight: 600;}

/* Diversity Sec  */
.diversity-sec{background: #F0F1F2;position: relative;}
.top-right-line{position: absolute;right: 0;top: 0;}

/* Our Services Sec  */
.service-sec{position: relative;background: #F0F1F2;}
.service-sec .inner-row{gap: 20px 0;}
.service-sec .service-card{padding: 20px;box-shadow: 0px 0px 16px 0px #2125291A;border-radius: 10px;background: #fff;display: flex;align-items: center;gap: 0 20px;position: relative;z-index: 1;height: 100%;}
.service-sec .service-card .icon-div{width: 110px;height: 110px;border-radius: 10px;background: #FEF2F3;display: flex;align-items: center;justify-content: center;}
.service-sec .service-card .text-content{display: flex;flex-direction: column;gap: 20px 0;}
.service-sec .service-card .text-content .title{font-size: 20px;font-weight: 600;line-height: 28px;margin-bottom: 0;color: #212529;}
.read-more-btn{display: flex;align-items: center;gap: 0 10px;font-size: 16px;font-weight: 400;line-height: 24px;color: #444444;width: fit-content;}
.read-more-btn:hover{color: #444444;}
.read-more-btn .icon{width: 29px;height: 29px;border-radius: 100px;border: 1px solid #5B595B;display: flex;align-items: center;justify-content: center;}

/* Why Ibc Home Sec  */
.why-ibc-home-sec .inner-row{gap: 20px 0;}
.why-ibc-home-sec .inner-card{display: flex;flex-direction: column;}
.why-ibc-home-sec .inner-card .title{font-size: 22px;font-weight: 600;line-height: 30px;color: var(--primary-color);padding-bottom: 10px;margin-bottom: 15px;border-bottom: 1px solid #DDDDDD;}
.why-ibc-home-sec .inner-card .message{display: flex;flex-direction: column;gap: 10px 0;}
.why-ibc-home-sec .inner-card .message .p-text{font-style: italic;}
.why-ibc-home-sec .inner-card .message .bold{font-weight: 600;color: #212529;font-style: normal;}

/* Terms and Conditions */
.terms-policy-content{display: flex;flex-direction: column;gap: 25px 0;}
.terms-policy-content .p-text-div{gap: 10px 0;}
.terms-policy-content .heading{color: #000;font-size: 22px;line-height: 26px;margin-bottom: 10px;}
.terms-policy-content .sub-heading{color: #000;font-size: 16px;line-height: 18px;margin-bottom: 5px;}
.terms-policy-content ul li{font-size: 16px;line-height: 26px;color: #444;}

/* Our Approach Sec */
.our-approach-sec .approach-row{gap: 30px 0;}
.our-approach-sec .approch-card{display: flex;flex-direction: column;gap: 20px 0;justify-content: center;align-items: center;}
.our-approach-sec .approch-card .icon-div{width: 100px;height: 100px;display: flex;align-items: center;justify-content: center;background: #FEF2F3;border-radius: 10px;line-height: 50px;font-size: 62px;font-weight: 600;color: var(--primary-color);}
.our-approach-sec .approch-card .text-div{display: flex;flex-direction: column;align-items: center;justify-content: center;gap: 7px 0;}
.our-approach-sec .approch-card .text-div .title{margin-bottom: 0;font-size: 20px;font-weight: 600;line-height: 28px;color: #212529;text-align: center;}
.our-approach-sec .approch-card .text-div .p-text{text-align: center;max-width: 262.5px;}

/* Insights Page */
.filter-row-parent{display: flex;gap: 21px;margin-bottom: 34px;}
.filter-row{gap: 30px 0;}
.insights-grid{display: grid;grid-template-columns: repeat(3,1fr);gap: 30px;}
.insights-card{padding: 15px;border-radius: 10px;box-shadow: 0px 0px 20px 0px #21252929;background: #fff;display: flex;flex-direction: column;height: 100%;justify-content: space-between;transition: all 0.2s ease-in-out;}
.insights-card .top-content .title:hover{color: var(--primary-color);}
.insights-card .top-content{display: flex;flex-direction: column;gap: 15px 0;padding-bottom: 15px;margin-bottom: 15px;border-bottom: 1px solid #DDDDDD;}
.insights-card .top-content .title{font-size: 25px;font-weight: 600;line-height: 35px;color: #212529;margin-bottom: 0;display: -webkit-box;-webkit-line-clamp: 3;-webkit-box-orient: vertical;overflow: hidden;text-overflow: ellipsis;}
.insights-card .top-content .text{display: -webkit-box;-webkit-line-clamp: 4;-webkit-box-orient: vertical;overflow: hidden;text-overflow: ellipsis;}
.insights-card .bottom-content .date-info{display: flex;align-items: center;justify-content: space-between;margin-bottom: 15px;}
.insights-card .bottom-content .date-info .info-text{display: flex;align-items: center;gap: 0 6px;font-size: 14px;font-weight: 400;line-height: 22px;margin-bottom: 0;}
.insights-card .bottom-content .date-info .icon{line-height: 0;color: #212529;font-size: 16px;}
.insights-card .tags{display: flex;flex-wrap: wrap;gap: 10px;margin-bottom: 30px;}
.insights-card .tags .tag-name{font-size: 14px;font-weight: 400;line-height: 22px;color: #444444;margin-bottom: 0;transition: all 0.2s ease-in-out;}
.insights-card .tags .tag-name:hover{color: var(--primary-color);}

/* Contact Us Sec */
.contact-section{padding-bottom: 48px;}
.contact-section .inner-content{display: grid;grid-template-columns: repeat(2,1fr);gap: 0 30px;}
.contact-section .left-cnt{padding-top: 70px;}
.contact-section .left-cnt .address-mdiv{display: flex;flex-direction: column;gap: 30px 0;margin: 0 0 30px 0;}
.contact-section .address-mdiv .address{display: flex;align-items: center;gap: 0 15px;}
.contact-section .address-mdiv .address .icon-div{width: 80px;height: 80px;border-radius: 10px;background:#FEF2F3;display: flex;align-items: center;justify-content: center;min-width: 80px;}
.contact-section .address-mdiv .address .address-cnt{display: flex;flex-direction: column;gap: 5px 0;}
.contact-section .address-mdiv .address .address-cnt .country-title{font-size: 18px;font-weight: 600;line-height: 26px;color: #212529;margin-bottom: 0;text-transform: uppercase;}
.contact-section .address-mdiv .address .address-cnt .address-list{display: flex;flex-direction: column;gap: 2px 0;margin-bottom: 0;padding-left: 20px;}
.contact-section .address-mdiv .address .address-cnt .address-list p, .contact-section .address-mdiv .address .address-cnt .address-list address{margin-bottom: 0;}
.contact-section .address-mdiv .address .address-cnt .text{font-size: 16px;font-weight: 400;line-height: 24px;color: #444444;margin-bottom: 0;}
.contact-section .address-mdiv .address .address-cnt .address-list li::marker{color: var(--primary-color);}
.contact-section .left-cnt .mail-div, .contact-section .left-cnt .social-div{display: flex;flex-direction: row;gap: 0 15px;align-items: center;}
.contact-section .left-cnt .mail-div{margin: 30px 0;padding-bottom: 30px;border-bottom: 1px solid #DDDDDD;}
.contact-section .left-cnt .mail-div .title, .contact-section .left-cnt .social-div .title{margin-bottom: 0;font-size: 18px;font-weight: 600;line-height: 26px;color: #212529;}
.contact-section .left-cnt .mail-div .mail-text{width: fit-content;transition: all 0.2s ease-in-out;}
.contact-section .left-cnt .mail-div .mail-text:hover{color: var(--primary-color);}
.contact-section .contact-social{display: flex;gap: 0 15px;}
.contact-section .contact-social .social-list-item .icn-img{width: 24px;height: 24px;}
.contact-section .contact-card{border-width: 0px 1px 1px 1px;border-style: solid;border-color:#DDDDDD;padding: 70px 34px 30px 34px;border-radius: 0 0 10px 10px;}
.contact-section .contact-card .heading-div{display: flex;flex-direction: column;gap: 15px 0;margin-bottom: 30px;}
.contact-section .contact-card .heading-div .heading{font-size: 30px;font-weight: 600;line-height: 38px;color: #212529;margin-bottom: 0;}
.contact-section .contact-card .form-control:focus{box-shadow: none;}
.contact-section .contact-card .form-floating .form-control{border-width: 0 0 1px 0;border-radius: 0;border-color: #DDDDDD;height: auto;font-size: 16px;line-height: 24px;color: #444444;font-weight: 400;min-height: 64px;padding: 25px 0 0 0;}
.contact-section .contact-card .form-floating>label{padding: 25px 0 15px 0;border: 0;font-size: 16px;line-height: 26px;}
.form-floating>.form-control-plaintext~label, .form-floating>.form-control:focus~label, .form-floating>.form-control:not(:placeholder-shown)~label, .form-floating>.form-select~label{color: #212529;}
.form-floating>.form-control-plaintext~label, .form-floating>.form-control:focus~label, .form-floating>.form-control:not(:placeholder-shown)~label, .form-floating>.form-select~label{transform: scale(1) translateY(-20px) translateX(0);opacity: 1 !important;}
.form-floating:has(textarea)>.form-control-plaintext~label, .form-floating:has(textarea)>.form-control:focus~label, .form-floating:has(textarea)>.form-control:not(:placeholder-shown)~label{transform: scale(1) translateY(-25px) translateX(0);}
.contact-section .contact-card .form-group{margin-bottom: 15px;}
.contact-section .contact-card .form-group:last-child{margin-bottom: 0;}
.contact-section .submitbtn-div{display: flex;justify-content: end;margin-top: 30px;}

/* Insights Details */
.insight-details-div > .title{font-size: 25px;font-weight: 600;line-height: 39px;color: #212529;margin-bottom: 15px;padding-bottom: 15px;border-bottom: 1px solid #DDDDDD;}
.insight-details-div .info{display: flex;justify-content: space-between;margin-bottom: 10px;}
.insight-details-div .info .left-cnt{display: flex;align-items: center;gap: 0 15px;}
.insight-details-div .info .left-cnt .info-text{display: flex;align-items: center;gap: 0 6px;font-size: 14px;font-weight: 400;line-height: 22px;margin-bottom: 0;}
.insight-details-div .info .left-cnt .icon{line-height: 0;color: #212529;font-size: 16px;}
.insight-details-div .info .social-div,.insight-details-div .tags-mdiv{display: flex;align-items: center;gap: 0 10px;}
.insight-details-div .info .social-div .title,.insight-details-div .tags-mdiv .title{margin-bottom: 0;font-size: 14px;font-weight: 500;line-height: 22px;color: #212529;}
.insight-details-div .info .social-div .contact-social{display: flex;gap: 0 15px;}
.insight-details-div .info .social-div .contact-social .social-list-item .icn-img{width: 24px;height: 24px;}
.insight-details-div .tags{display: flex;align-items: center;flex-wrap: wrap;gap: 0 10px;}
.insight-details-div .tags .tag{font-size: 14px;font-weight: 400;line-height: 22px;color: #444;margin-bottom: 0;transition: all 0.2s ease-in-out;}
.insight-details-div .tags .tag:hover{color: var(--primary-color);}
.insight-details-div .info-mdiv{display: flex;flex-direction: column;padding-bottom: 15px;margin-bottom: 15px;border-bottom: 1px solid #DDDDDD;}
.insight-details-div .ck-content{display: flex;flex-direction: column;gap: 15px 0;}
.recommended-post-div{position: sticky;top: 100px;}
.recommended-post-div > .title{font-size: 20px;font-weight: 600;line-height: 28px;color: #212529;margin-bottom: 20px;}
.recommended-post-div .posts{display: flex;flex-direction: column;gap: 15px 0;}
.recommended-post-div .recommended-card.insights-card .top-content .title{-webkit-line-clamp: 2;font-size: 18px;line-height: 26px;transition: all 0.2s ease-in-out;}
.recommended-post-div .recommended-card.insights-card .top-content .title:hover{color: var(--primary-color);}
.recommended-post-div .recommended-card.insights-card .top-content{gap: 10px 0;margin-bottom: 10px;padding-bottom: 10px;}
.recommended-post-div .recommended-card.insights-card .bottom-content .date-info{margin-bottom: 0;}
.recommended-post-div .recommended-card.insights-card .top-content .text{-webkit-line-clamp: 3}
.insight-details-div p{font-size: 16px;font-weight: 400;line-height: 24px;margin-bottom: 0;color: #444;}
.insight-details-div table{width: 100% !important;}
.insight-details-div table td, .insight-details-div table th{padding: 7px;word-break: break-word;border-color: #bbbbbb !important;border: 1px solid;}
.insight-details-div table b, .insight-details-div table strong{font-weight: 700 !important;}

/* Consulting Outcomes Sec */
.consult-outcome-sec .card-row{gap: 20px 0;}
.challenges-card.border-card{box-shadow: none;border: 1px solid #DDDDDD;align-items: center;text-align: center;}
.challenges-card.border-card .text-content .title, .challenges-card.trans-card .text-content .title{font-size: 18px;line-height: 28px;text-align: center;max-width: 230px;text-transform: capitalize;}
.challenges-card.trans-card .text-content .title{max-width: 262px;}
.challenges-card.border-card .text-content{gap: 0;}

.project-input-sec{background: #F0F1F2;}
.project-input-sec .card-row{gap: 20px 0;}
.challenges-card.trans-card{box-shadow: none;border: 0;padding: 0;background: transparent;align-items: center;text-align: center;}
.challenges-card.trans-card .icon-div{border: 1px solid #DDDDDD;border-radius: 10px;background: transparent;}

.case-study.sec-padding ~ .case-study.sec-padding{padding-top: 0;}
.case-study-card{border-radius: 10px;padding: 15px;box-shadow: 0px 0px 20px 0px #21252929;height: 100%;}
.case-study-card .title{color: var(--primary-color);font-size: 20px;font-weight: 600;line-height: 28px;padding-bottom: 10px;margin-bottom: 15px;border-bottom: 1px solid #DDDDDD;}
.case-study-card .content{display: flex;flex-direction: column;gap: 7px 0;}
.case-study-row{gap: 20px 0;}
.case-study-card .inner-card .img-div{height: 360px;border-radius: 4px;background: #FEF2F3;margin-bottom: 15px;display: block;}
.case-study-card .inner-card .img-div img{border-radius: 4px;width: 100%;height: 100%;}
.case-study-card .number-card .icon-div{width: 80px;height: 80px;border-radius: 10px;background: #FEF2F3;margin: auto auto 15px auto;display: flex;align-items: center;justify-content: center;font-size: 35px;font-weight: 600;line-height: 52.5px;text-align: center;color: var(--primary-color);}
.case-study-card .number-card .border-title{font-size: 18px;font-weight: 600;line-height: 26px;color: #212529;padding-bottom: 10px;margin-bottom: 15px;border-bottom: 1px solid #DDDDDD;}
.key-project-content .p-text{list-style: none;font-weight: 600;}

.rpv-core__menu div[aria-controls="rpv-core__modal-body-properties"], .rpv-core__menu-divider, .rpv-toolbar__item:has(div[aria-describedby="rpv-core__tooltip-body-open"]) , .rpv-toolbar__item:has(div[aria-describedby="rpv-core__tooltip-body-get-file"]){display: none !important;}


@media (max-width:1799px) {
  .hws-sec .inner-content .consulting-lifecycle-img{height: 420px;}
}
@media (max-width:1399px) {
  .main-banner-section .text-content .title{font-size: 48px;}

  /* How We Serve Section */
  .hws-sec .inner-content:nth-child(1) > .title{margin-bottom: 121px;}
  .hws-sec .inner-content:nth-child(3) .title{margin-bottom: 45px;}
  .hws-sec .inner-content .consulting-lifecycle-img{height: 350px;}
  .hws-sec .inner-content:nth-child(3) .single-card .number-div .number{font-size: 60px;}
  .hws-sec .inner-content:nth-child(3) .single-card{margin-bottom: 35px;padding-bottom: 35px;}
  .hws-sec .dots{margin: 90px auto auto auto;}
}
@media (max-width:1299px) {
  .hws-sec .dots{margin: 40px auto auto auto;}
}
@media (max-width:1199px) {
  /* Common CSS  */
  .common-heading-text{font-size: 34px;line-height: 48px;}

  /* Main Banner Section  */
  .main-banner-section .text-content .title{font-size: 37px;line-height: 56px;}

  /* How We Serve Section */
  .hws-sec .inner-content:nth-child(3) .single-card .text-conent .name{font-size: 18px;line-height: 24px;}
  .hws-sec .inner-content:nth-child(3) .single-card .text-conent .p-text{font-size: 16px;line-height: 22px;}
  .hws-sec .inner-content:nth-child(3) .single-card{margin-bottom: 30px;padding-bottom: 30px;}
  .hws-sec .inner-content .cards .title{font-size: 22px;line-height: 28px;}

  /* Our Services Sec  */
  .service-sec .service-card .icon-div{width: 80px;height: 80px;min-width: 80px;}
  .service-sec .service-card .icon-div .icon{height: 40px;}
  .service-sec .service-card .text-content{gap: 15px 0;}
  .service-sec .service-card .text-content .title{font-size: 18px;line-height: 23px;}
  .service-sec .service-card{padding: 14px;gap: 0 15px;height: 100%;}

  /* Insights Page */
  .insights-grid{grid-template-columns: repeat(2,1fr);}

  /* Footer  */
  footer .copyright-footer .inner-content{flex-direction: column;gap: 10px 0;}
}
@media (max-width:991px) {
  
  /* Common CSS  */
  .common-heading-text{font-size: 28px;line-height: 38px;}

  .col-right{padding-left: 10.5px;}
  .col-left{padding-right: 10.5px;}

  /* Main Banner Section  */
  .main-banner-section{padding: 54px 0;}
  .main-banner-section .text-content .sub-text{margin-bottom: 35px;}

  .challenge-section .heading-div{padding-top: 0;}

  /* How We Serve Section */
  .hws-sec .inner-content{padding: 30px 40px;}
  .hws-sec .inner-content .title{font-size: 22px;line-height: 32px;}
  .hws-sec .inner-content .cards .card-imgs{height: 80px;object-fit: contain;}
  .hws-sec .inner-content .cards.and-div{min-width: 50px;}
  .hws-sec .inner-content:nth-child(3) .single-card{margin-bottom: 15px;padding-bottom: 15px;gap: 0 10px;}
  .hws-sec .inner-content:nth-child(3) .single-card .text-conent{gap: 7px 0;}
  .hws-sec .inner-content .right-icon{height: 50px;top: 50px;}
  .hws-sec .dots{margin: 70px auto auto auto;gap: 10px 0;}
  .hws-sec .dots .round-dot{width: 40px;height: 40px;}
  .hws-sec .inner-content:nth-child(3) .single-card .number-div .number{font-size: 38px;line-height: 46px;min-width: auto;}

  /* Testimonials Section */
  .testimonials-sec .slider-content .inner-content{padding: 20px;}

  /* Why We do What We Do */
  .why-we-sec .card-row .value-card .icon-div{width: 80px;height: 80px;}
  .why-we-sec .card-row .value-card .icon-div .value-icon{width: 45px;height: 45px;}
  .why-we-sec .card-row .value-card .value-title{font-size: 18px;}

  .filter-row-parent{flex-direction: column;}

  /* Insights Page */
  .insights-card .top-content .title{font-size: 21px;font-weight: 600;line-height: 32px;}
  .insight-details-row{gap: 30px 0;}

  /* Contact Us Page */
  .contact-section .left-cnt{padding-top: 54px;}
  .contact-section .inner-content{grid-template-columns: repeat(1,1fr);gap: 30px;}
  .contact-section .contact-card{border: 1px solid #DDDDDD;border-radius: 10px;}
}
@media (max-width:767px) {
  .w-lg-100{width: fit-content;}

  /* How We Serve Section */
  .hws-sec .left-content{padding-top: 35px;position: unset;height: 100%;padding-bottom: 30px;}
  .hws-sec .inner-content{min-height: fit-content;background: transparent;}
  .hws-sec .inner-content:nth-child(1) .content{position: unset;transform: unset;}
  .hws-sec .dots{display: none;}
  .hws-sec .inner-content:nth-child(1) > .title, .hws-sec .inner-content:nth-child(3) .title{margin-bottom: 20px;}

  /* Why We do What We Do */
  .why-we-sec .card-row{grid-template-columns: repeat(2,1fr);}
  .why-we-sec .card-row .value-card:nth-child(even){border-right: 1px solid #ddd;}
  .why-we-sec .inner-content .quote-text{font-size: 16px;line-height: 26px;margin-top: 10px;}
  .why-we-sec .inner-content{gap: 20px 0;}
  .why-we-sec .inner-content .values-div .bold-title{font-size: 16px;line-height: 22px;margin-bottom: 10px;}

  /* Our Services Sec  */
  .service-sec .inner-row{gap: 15px 0;}

  .team-member-sec .inner-row{max-height: 435px;}

  /* Insights Page */
  .insights-grid{grid-template-columns: repeat(1,1fr);gap: 15px 0;}
  .insight-details-div .info{flex-direction: column;gap: 10px 0;}
  .insight-details-div > .title{font-size: 22px;line-height: 32px;}
  .recommended-post-div > .title{font-size: 18px;line-height: 24px;margin-bottom: 15px;}

  /* Contact Us Page */
  .contact-section .contact-card{padding: 45px 25px 20px 25px;}

  .insight-details-div table td, .insight-details-div table th{padding: 3px;}
}
@media (max-width:576px) {

  /* Common CSS  */
  .primary-arrow-btn{padding: 12px 16px;font-size: 14px;line-height: 22px;gap: 0 4px;}
  .primary-arrow-btn .btn-icon{width: 18px;height: 18px;font-size: 16px;}

  .common-sub-heading{font-size: 14px;font-weight: 500;line-height: 18px;}
  .common-heading-text{font-size: 22px;line-height: 32px;text-align: center;}

  .p-text{font-size: 14px;line-height: 24px;}
  .p-text-div{gap: 10px 0;}

  .sec-padding{padding: 35px 0;}
  .common-heading{margin-bottom: 20px;}

  .bordered-input{padding: 10px 14px;font-size: 14px;line-height: 22px;}

  /* Main Banner Section  */
  .main-banner-section{padding: 35px 0;}
  .main-banner-section .text-content .title{font-size: 24px;line-height: 36px;margin-bottom: 15px;}
  .main-banner-section .text-content .sub-text{font-size: 16px;line-height: 24px;margin-bottom: 25px;}
  .main-banner-section .text-content .btn-grp{gap: 0 10px;}
  .main-banner-section .banner-vector{height: 280px;}

  /* Your Challenges Section */
  .challenge-section{padding: 35px 0;}
  .challenge-section .heading-div{margin-bottom: 15px;}
  .challenge-section .inner-card-row, .challenge-section .challenge-sec-row{gap: 15px 0;}
  .challenges-card .icon-div{width: 70px;height: 70px;padding: 0;}
  .challenges-card .icon-div .icon{height: 35px;}
  .challenges-card{padding: 16px;gap: 15px 0;}
  .challenges-card .text-content .title{font-size: 18px;line-height: 24px;}
  .challenges-card .text-content{gap: 6px 0;}

  /* How We Serve Section */
  .hws-sec{padding-bottom: 35px;}
  .hws-sec .inner-content{padding: 0 15px 35px 15px;}
  .hws-sec .inner-content:nth-last-child(1){padding-bottom: 0;}
  .hws-sec .inner-content .title{font-size: 18px;line-height: 24px;}
  .hws-sec .inner-content .cards .card-imgs{height: 50px;}
  .hws-sec .inner-content:nth-child(1) .content{justify-content: center;gap: 0 15px;}
  .hws-sec .inner-content .cards .title{font-size: 16px;line-height: 24px;}
  .hws-sec .inner-content .right-icon{height: 28px;top: 20px;display: none;}
  .hws-sec .inner-content .consulting-lifecycle-img{height: 340px;}
  .hws-sec .inner-content:nth-child(3) .single-card .text-conent .name{font-size: 16px;line-height: 22px;}
  .hws-sec .inner-content:nth-child(3) .single-card .text-conent .p-text{font-size: 14px;}
  .hws-sec .inner-content:nth-child(3) .single-card .number-div .number{font-size: 28px;line-height: 32px;}
  .heading-div{text-align: center;}

  /* Testimonials Section */
  .testimonials-sec{padding: 35px 0 45px 0;}
  .testimonials-sec .slider-content .inner-content{padding: 16px;min-height: 150px;gap: 14px 0;}
  .testimonials-sec .slider-content .inner-content .name{font-size: 14px;}
  .testimonials-sec .slick-slide > div{margin: 0 7.5px;}

  /* Breadcrumb Section */
  .breadcrumbs-sec{padding: 25px 0;}
  .breadcrumbs-sec .breadcrumb-line{width: 280px;}
  .breadcrumbs-sec .text{font-size: 18px;line-height: 28px;position: relative;z-index: 2;}
  .breadcrumbs-sec .page-name{margin-bottom: 5px;}

  /* Why We do What We Do */
  .why-we-sec .card-row .value-card{gap: 15px 0;padding: 0 15px 15px 15px;}
  .why-we-sec .card-row .value-card .value-title{font-size: 16px;line-height: 22px;}
  .why-we-sec .card-row .value-card .icon-div .value-icon{width: 40px;height: 40px;}
  .why-we-sec .card-row .value-card .icon-div{width: 70px;height: 70px;}
  .why-we-sec .card-row .value-card:nth-child(3), .why-we-sec .card-row .value-card:nth-child(4), .why-we-sec .card-row .value-card:nth-child(5){border-top: 0 !important;}

  /* Background Sec */
  .background-sec .inner-card-row{gap: 15px 0;}

  /* Team Members Sec */
  .team-member-sec .inner-row{gap: 15px 0;}
  .team-member-sec .member-card{padding: 12px;}
  .team-member-sec .member-card .top-content .member-img{width: 70px;height: 70px;}
  .team-member-sec .member-card .top-content .name-info .name{font-size: 16px;line-height: 24px;}
  .team-member-sec .member-card .top-content .name-info{gap: 3px 0;}

  /* Why IBC Sec */
  .why-ibc-sec .tab-sec{gap: 20px 0;}
  .why-ibc-sec .tab-sec .nav{padding: 8px;gap: 5px;justify-content: center;}
  .why-ibc-sec .tab-sec .nav .nav-item .nav-link{padding: 5px 10px;font-size: 14px;font-weight: 600;line-height: 22px;}
  .why-ibc-sec .tab-sec .inner-card-row{gap: 15px 0;}

  /* Roles Sec */
  .roles-sec .inner-content .subtitle, .roles-sec .inner-content .role-cnt .single-cnt .text .title{font-size: 16px;line-height: 24px;}
  .roles-sec .inner-content .role-cnt{padding-left: 15px;padding-bottom: 10px;margin-bottom: 0;}

  /* Our Services Sec  */
  .service-sec .service-card .text-content .title{font-size: 16px;}
  .read-more-btn{font-size: 14px;line-height: 22px;gap: 0 6px;}
  .read-more-btn .icon{width: 20px;height: 20px;font-size: 12px;}

  /* Why Ibc Home Sec  */
  .why-ibc-home-sec .inner-row{gap: 15px 0;}
  .why-ibc-home-sec .inner-card .title{font-size: 18px;line-height: 24px;margin-bottom: 10px;padding-bottom: 10px;}
  .why-ibc-home-sec .inner-card .message{gap: 5px 0;}

  /* Terms and Conditions */
  .terms-policy-content .sub-heading{margin-bottom: 0;}
  .terms-policy-content{gap: 20px 0;}
  .terms-policy-content .sub-heading{line-height: 22px;}
  .terms-policy-content .p-text-div{gap: 5px 0;}
  .terms-policy-content .heading{font-size: 18px;}
  .terms-policy-content ul li{font-size: 14px;line-height: 24px;}
  
  /* Our Approach Sec */
  .our-approach-sec .approach-row{gap: 15px 0;}
  .our-approach-sec .approch-card .icon-div{width: 70px;height: 70px;font-size: 42px;}
  .our-approach-sec .approch-card{gap: 15px 0;}
  .our-approach-sec .approch-card .text-div .title{font-size: 18px;line-height: 26px;}
  .our-approach-sec .approch-card .text-div{gap: 4px 0;}
  .our-approach-sec .approch-card .text-div .p-text{max-width: 200px;}

  /* Insights Page */
  .filter-row-parent{margin-bottom: 25px;gap: 15px 0;}
  .filter-row{gap: 15px 0;}
  .insights-card{padding: 12px;}
  .insights-card .top-content .title{font-size: 16px;line-height: 26px;}
  .insights-card .top-content{gap: 10px 0;margin-bottom: 10px;padding-bottom: 10px;}
  .insights-card .bottom-content .date-info{margin-bottom: 10px;}
  .insights-card .tags{margin-bottom: 15px;}
  .insight-details-div > .title{font-size: 18px;line-height: 28px;margin-bottom: 10px;padding-bottom: 10px;}
  .insight-details-div .info .social-div .contact-social .social-list-item .icn-img{width: 20px;height: 20px;}
  .insight-details-div .info .social-div .contact-social{gap: 0 10px;}
  .insight-details-div .info .left-cnt{flex-wrap: wrap;gap: 10px;}
  .insight-details-div .tags{gap: 5px 10px;}
  .insight-details-div .tags-mdiv{align-items: flex-start;}
  .insight-details-div .info-mdiv{margin-bottom: 10px;padding-bottom: 10px;}
  .insight-details-row{gap: 20px 0;}
  .recommended-post-div .recommended-card.insights-card .top-content .title{font-size: 16px;line-height: 26px;}
  .recommended-post-div .recommended-card.insights-card .top-content{gap: 5px 0;}
  .insight-details-div p{font-size: 14px;line-height: 22px;}

  /* Contact Us Page */
  .contact-section .left-cnt{padding-top: 35px;}
  .contact-section .address-mdiv .address{gap: 0 10px;}
  .contact-section .address-mdiv .address .address-cnt .country-title{font-size: 16px;line-height: 24px;}
  .contact-section .address-mdiv .address .address-cnt .text{font-size: 14px;line-height: 22px;}
  .contact-section .address-mdiv .address .icon-div{min-width: 60px;height: 60px;padding: 12px;width: 60px;}
  .contact-section .contact-card .heading-div{text-align: left;gap: 10px 0;margin-bottom: 20px;}
  .contact-section .submitbtn-div{margin-top: 20px;}
  .contact-section .contact-card{padding: 25px 15px;}
  .contact-section .contact-card .heading-div .heading{font-size: 21px;line-height: 28px;}
  .contact-section .left-cnt .mail-div .title, .contact-section .left-cnt .social-div .title{font-size: 16px;line-height: 24px;}
  .contact-section .left-cnt .mail-div{margin: 15px 0;padding-bottom: 15px;}
  .contact-section .contact-card .form-floating .form-control{font-size: 14px;line-height: 22px;}
  .contact-section .contact-card .form-floating>label{font-size: 14px;line-height: 22px;}
  .contact-section .contact-card .form-group{margin-bottom: 10px;}
  .contact-section .contact-social .social-list-item .icn-img{width: 20px;height: 20px;}
  .contact-section .left-cnt .address-mdiv{gap: 20px 0;}
  
  /* Consulting Outcomes Sec */
  .consult-outcome-sec .card-row{gap: 15px 0;}
  .challenges-card.border-card{max-width: 320px;margin: auto;}
  .challenges-card.border-card .text-content .title, .challenges-card.trans-card .text-content .title{font-size: 14px;line-height: 22px;}
  .consult-outcome-sec .challenges-card{padding: 12px;}

  /* Services */
  .case-study-card{padding: 12px;}
  .case-study-card .title{font-size: 16px;line-height: 24px;margin-bottom: 10px;}
  .case-study-card .inner-card .img-div{height: 200px;margin-bottom: 10px;}
  .case-study-row{gap: 15px 0;}
  .case-study-card .number-card .icon-div{width: 60px;height: 60px;font-size: 28px;line-height: 44.5px;margin: auto auto 10px auto;}
  .case-study-card .number-card .border-title{font-size: 16px;line-height: 22px;margin-bottom: 10px;}
  
  .diversity-sec .top-right-line{display: none;}
  
  /* Footer  */
  .footer{padding: 30px 0;}
  .footer .brand-logo-img{height: 65px;margin-bottom: 10px;}
  .footer .address-mrow .icon-text .text, .footer .address-mrow .icon-text .text > p, footer .copyright-footer .inner-content .copyright-footer-text{font-size: 14px;line-height: 22px;}
  .footer .address-mrow .icon-text .icon{width: 16px;height: 16px;font-size: 16px;}
  .footer .footer-heading-text{margin-top: 15px;margin-bottom: 10px;font-size: 16px;line-height: 24px;}
  .footer .socials{margin-top: 15px;padding-top: 15px;gap: 0 10px;}
  footer .copyright-footer .inner-content .copyright-footer-text{text-align: center;}
  footer .copyright-footer .inner-content p{display: block;}
  .footer .socials .icn-img{width: 20px;height: 20px;}
  .footer .link-list{gap: 6px 0;}
  .footer .link-list .footer-link{font-size: 14px;line-height: 22px;}
  .footer .link-list .footer-link .icon{height: 16px;}
  .footer .link-list .footer-link:hover .icon{width: 16px;}
}