:root {--primary-color: #AD1F25;--secondary-color: #ffffff;}
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.bg-theme {background-color: var(--primary-color)!important;}
.bg-accent {background-color: var(--accent-color)!important;}
.text-theme {color: var(--primary-color)!important;}
.text-lighter {color: rgba(255, 255, 255, 0.6)!important;}


html, body {font-family:  "Poppins", sans-serif; font-size: 14px;scroll-behavior: auto !important;}
body {background: #fff;}
body.login-page {background: var(--primary-color);}
.fixed-footer {position: fixed;bottom: 20px;height: 40px;width: 100%;right: 0;bottom: 0;padding: 0 15px;background: #fff;display: flex;justify-content: center;align-items: center;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;}
.fixed-footer p{ width: 100%;overflow: hidden;text-overflow: ellipsis;}
.fixed-footer p,.fixed-footer p a{font-size: 15px; font-weight: 400; line-height: 21px; color: #000;}
.fixed-footer p a:hover{color: var(--primary-color);}
.card-login {width: 380px; max-width: 90%;}
label.invalid-input {color: #dc3545; font-size: 90%; margin-bottom: 0; }
.btn.focus, .btn:focus, .btn:focus-visible,.btn-danger:not(:disabled):not(.disabled).active:focus, .btn-danger:not(:disabled):not(.disabled):active:focus, .show>.btn-danger.dropdown-toggle:focus,.btn-close:focus{outline: 0 !important;box-shadow: none !important;}
input[type ="checkbox"]{accent-color: var(--primary-color);}
input[type ="radio"]{accent-color: var(--primary-color);}

/* ------------------------------------------------ MD Time Picker ------------------------------------------------ */

.mdtimepicker .mdtp__clock_holder .mdtp__clock .mdtp__digit.active span, .mdtimepicker .mdtp__clock_holder .mdtp__clock .mdtp__digit:not(.digit--disabled) span:hover,.mdtimepicker .mdtp__clock_holder .mdtp__clock .mdtp__digit.active:before,.mdtp__wrapper[data-theme=primary-color] .mdtp__clock .mdtp__am.active, .mdtp__wrapper[data-theme=primary-color] .mdtp__clock .mdtp__clock_dot, .mdtp__wrapper[data-theme=primary-color] .mdtp__clock .mdtp__pm.active, .mdtp__wrapper[data-theme=primary-color] .mdtp__time_holder{background-color: var(--primary-color) !important; }
.mdtimepicker .mdtp__wrapper{left: 50%;top: 50%;transform: translate(-50%, -50%);bottom: 0;height: fit-content;}
.twt-time-picker{padding-right: 30px; position: relative; }
.twt-time-picker-div{position: relative; }

.twt-time-picker-div::before{position: absolute; content: '\f017'; width: 40px; height: 100%; font: var(--fa-font-regular); z-index: 6; top: 50%; right: 0; transform: translateY(-50%); display: flex; align-items: center; padding-left: 10px;}
/* ------------------------------------------------ MD Time Picker ------------------------------------------------ */

/* ------------------------------------------------ show toggle ------------------------------------------------ */


.breadcrumb-wrapper {top:0; left: 0;right: 0; background-color: #fff; padding: 8px 14px; align-items: center;}
.breadcrumb-wrapper a {color: #fff;}
.breadcrumb-wrapper a:hover{color: var(--primary-color);}

.btn:not(.btn-link):hover {box-shadow: 0 4px 10px -2px rgba(30,30,30,0.5);}
.btn-wide {width: 100px;}

.card-shadow { box-shadow: 0 2px 8px -2px rgba(0,0,0,.15)}
.card-full-height {height: 100%;}
.card-sp {position: relative;border: none;border-radius: 7px;}
.card-sp {box-shadow: 0 3px 1px -2px rgba(0,0,0,0.2), 0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12);}
.card-sp:after {content:''; position: absolute; width: 50%; height: 100%; right: 0;top: 0;}
.card-sp .card-body {position: relative;}

.alertify-notifier .ajs-message {border: none!important; text-shadow: none!important; border-radius: 4px; box-shadow: 0 4px 8px -2px rgba(0,0,0,.5)}
.ajs-message.ajs-visible{color: #fff; text-align: center;}
.ajs-message p{ margin-bottom: 0;}
/*overlay*/
.overlay-block {position: fixed;height: 100%;width: 100%;top: 0;left: 0;background-color: rgba(35, 35, 35, 0.75);z-index: 999999;color: #fff;}
.overlay-block>* {position: absolute;transform: translate3d(-50%, -50%, 0);top: 50%;left: 50%;}


/* dashboard */
.dash-links,.dash-links:hover {text-decoration: none;}
.stats-label {font-size: 55px;color: var(--primary-color);text-shadow: 2px 2px rgba(30,30,30,0.2);font-weight: 700;opacity: 0.6;}

/* custom-table */
.custom-table {min-width: 720px; width: 100%; border-collapse: collapse; border-spacing: 0; border: 1px solid #ddd; font-size: 0.75rem;}
.custom-table th,
.custom-table td {border: 1px solid #ddd; padding: 0.25rem 0.5rem; text-align: center;}
.custom-table th:first-child {min-width: 150px;width: 150px;}


/* print */
@media print {
    @page {margin: 0;}
    .wrapper * {box-shadow: none !important;}
    .wrapper {padding: 0!important;}
    .wrapper .content {padding: 1rem}
    .breadcrumb-wrapper {display: none;}
}

label.invalid-input {color: #dc3545; font-size: 90%; margin-bottom: 0;}


/* ------------------------------------------------  settings ------------------------------------------------*/

/* this css for chenge the text in custom-switch-button */

.custom-switch.custom-switch-enable-disable .custom-control-input~.custom-control-label span:before{content:'Disable';white-space:nowrap;}
.custom-switch.custom-switch-enable-disable .custom-control-input:checked~.custom-control-label span:before{content:'Enable'}


.custom-switch.custom-switch-enable-disable.custom-switch-yes-no .custom-control-input~.custom-control-label span:before{content:'No';white-space:nowrap;}
.custom-switch.custom-switch-enable-disable.custom-switch-yes-no .custom-control-input:checked~.custom-control-label span:before{content:'Yes'}

.form-check-input:checked~.form-check-label span:before{content:'Enable'}
.form-check-input~.form-check-label span:before{content:'Disable'}
.form-check-input:focus{box-shadow: none;}

/*custom-switch-button end*/


.sticky-div{position:sticky;bottom:0;box-shadow:none!important;text-align:center;display:inline-flex;align-items:center;width:100%;z-index:9;border:none;border-radius:0; padding: 10px; background: #fff; gap: 5px; border-top: 1px solid #ddd;}
.modal .sticky-div { border-bottom-left-radius: var(--bs-modal-inner-border-radius); border-bottom-right-radius: var(--bs-modal-inner-border-radius); }
.sticky-div-center{justify-content:center;}
.sticky-div-end{justify-content:flex-end;}
.custom-switch-table{display: flex;justify-content: center;align-items: center;}
.custom-switch-table .custom-switch{width:100px;}
.list-of-other-master-div .card {padding: 15px;box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 15%);background: #fff;border: none;border-radius: 0;}
.my-delete-btn{display: flex;justify-content: center;align-items: center;border-radius: 50%;background-color: var(--primary-color);font-size: 15px;width: 30px;height: 30px;position: absolute;top: -12px;right: -10px;text-decoration: none;}


.setting-part {display: flex;align-items: center;padding: 10px;background-color: #fff;z-index: 3;border: 1px solid #f1f1f1;position: sticky;top: 50px;left: 0;right: 0; overflow-x: auto;}
.setting-part .setting-link{background-color: var(--primary-color);color: #fff;border-radius: 3px;text-align: center;padding: 10px 8px;white-space: nowrap;margin: 0 10px;}
.setting-logo {width: 100%;object-fit: contain;margin: 0 auto;display: flex;height: 140px;}
.setting-remove-btn.remove-banner-btn {position: absolute;top: -15px;right: -2px;}
.remove-banner-btn .close-button {width: 30px;height: 30px;line-height: 30px;padding: 0;text-align: center;}
.fa-spin{color: #fff !important;}

@media (max-width:991px) {
.login-brand-logo {width: 45% !important; margin-bottom: 0 !important; margin-top: 0 !important; }

}

@media (max-width:767px) {
.login-brand-logo {width: 55% !important; margin-top: 0px !important;}
}
@media (max-width:575px){
.card-login{margin-top: 20px !important; width: 100%;max-width: 100%;}
}

.alertify .ajs-dialog { max-width: 600px; min-height: 122px; background-color: #fff; border: 1px solid rgba(0, 0, 0, 0.2); -webkit-box-shadow: 0 5px 15px rgb(0 0 0 / 50%); box-shadow: 0 5px 15px rgb(0 0 0 / 50%); border-radius: 6px; }

.alertify .ajs-header { color: #333; border-bottom: 1px solid #e5e5e5; border-radius: 6px 6px 0 0; font-family: "Poppins", sans-serif !important; font-size: 18px; }
.alertify .ajs-body{font-family: "Poppins", sans-serif !important;}
.alertify .ajs-footer { background-color: #fff; padding: 15px; border-top: 1px solid #e5e5e5; border-radius: 0 0 6px 6px; }
.alertify .ajs-body .ajs-content{padding-left: 0;}
div.table-responsive > div.dataTables_wrapper > div.row > div[class^="col-"]:first-child {overflow: auto;}
.user-main-div .table-responsive{overflow-x:unset;}
.horizontal-header .setting-part{top: 105px;}

/* -------------------------------------- datatable ---------------------------------- */

div.table-responsive > div.dataTables_wrapper > div.row > div[class^="col-"]:first-child { overflow: auto; }
@media screen and (max-width: 767px){
  div.dataTables_wrapper div.dataTables_length, div.dataTables_wrapper div.dataTables_filter, div.dataTables_wrapper div.dataTables_info, div.dataTables_wrapper div.dataTables_paginate {text-align: left !important;}
}
.dtfh-floatingparenthead table.dataTable{border-collapse: collapse !important;}
.dataTable td{background: white !important;position: relative;}
.dataTables_scrollBody{height: auto !important;}
div.dataTables_wrapper div.dataTables_info{white-space: pre-wrap !important;}
#user-table_wrapper table.dataTable{border-collapse: collapse !important;}

@media (max-width: 992px) {.login-items {margin: 10px 0;padding: 0;} .otp-icon {font-size: 40px;} }


.forgot-password-page .back-to-login {color: var(--primary-color);font-size: 16px;font-weight: 500;padding-top: 20px;display: block;text-align: center;}
.forgot-password-page .back-to-login:hover {color: #212529;}


/* File Preview */

/* File Preview  single*/

.file-name-preview {width: 100%;height: 50px; border-radius: 4px;border: 1px solid #ddd; display: flex; align-items: center; justify-content: space-between;padding: 5px; margin-top: 10px;} 
.file-name-preview .file_upload_with_preview_delete-preview{width: calc(100% - 30px); overflow: hidden; white-space: nowrap; text-overflow: ellipsis;}
.file-name-preview button{ border-radius: 50%;width: 30px;font-size: 16px;display: flex;justify-content: center;align-items: center;height: 30px;line-height: 30px;}  

/* image Name Preview  Multiple */

.file-preview-mrow .file-preview-mdiv{min-width: 33.33%;max-width: 33.33%;padding: 0 15px;} 

/* File Name Preview  Multiple */

.file-name-preview-mrow .file-preview-mdiv{min-width: 50%;max-width: 50%;height: 50px;border-radius: 4px;display: flex;align-items: center;justify-content: space-between;padding: 0 15px;} 
.file-name-preview-mrow .file-preview-mdiv .upload-main-image{display: flex;align-items: center;justify-content: space-between;padding: 5px;width: 100%;word-wrap: break-word;word-break:break-all;border: 1px solid #ddd; }
.file-name-preview-mrow .file-preview-mdiv .upload-main-image .file-name-preview-label{ width: calc(100% - 30px); overflow: hidden; white-space: nowrap; text-overflow: ellipsis;}
.file-name-preview-mrow .file-preview-mdiv .upload-main-image .close-buttons{position: relative;top:initial;left: initial; top: 0; right: 0; min-width: 30px; width: 30px;}
.file-name-preview-mrow .file-preview-mdiv .file-name-preview-label{margin: 0;}


@media (max-width: 992px) {
  .file-preview-mrow .file-preview-mdiv{min-width: 50%;max-width: 50%;} 
}

@media (max-width: 568px) {
  .file-preview-mrow .file-preview-mdiv{min-width: 100%;max-width: 100%;} 
  .file-name-preview-mrow .file-preview-mdiv{min-width: 100%;max-width: 100%;} 
  .fc .fc-toolbar{flex-direction: column-reverse; }
  .fc-toolbar-chunk{margin-top: 5px;}
	.fc .fc-button{padding: .4em .45em !important; font-size: .84em !important;}
	.fc .fc-toolbar-title {font-size: 1.45em !important;}
	.fc .fc-toolbar.fc-header-toolbar{margin-bottom: 1rem !important;}
	.fc-day  { min-height: 45px; }
	.fc-time-grid .fc-slats td { height: 3.5em; }
}


/*---------------------bootstrap vs-5 changes--------------------*/
 
.btn-theme:hover{background-color: var(--primary-color) ;}
.btn-theme:active{background-color: var(--primary-color) !important;}
.btn-info{color: #fff;}
.btn-info:hover{color: #fff;}
.page-link:focus{box-shadow: none;}
.twt-custom-switch{display: flex; justify-content: flex-start;}
.twt-custom-switch .form-check-label{margin-left: 6px;}
.wrapper .sidebar .sidebar-nav a {padding:0.5rem 1rem; width: 100%;}
.wrapper .navbar {padding: 0.5rem 1rem; z-index: 1001;}
.form-group{margin-bottom:1rem;position: relative;}
a{text-decoration: none;}
.gallery-image-div{position: relative;}

.all-elements-page .twt-custom-switch{ justify-content: left;}
body.modal-open{overflow: hidden; padding-right: 0px;}

.custom-file {position: relative;display: inline-block;width: 100%;height: calc(1.5em + 0.75rem + 2px);margin-bottom: 0;}

.custom-file-input {position: relative;z-index: 2;width: 100%;height: calc(1.5em + 0.75rem + 2px);margin: 0;opacity: 0;}
.custom-file-label {border-color: #e8e7e7;}
.custom-file-label {position: absolute;top: 0;right: 0;left: 0;z-index: 1;height: calc(1.5em + 0.75rem + 2px);padding: 0.375rem 0.75rem;font-weight: 400;line-height: 1.5;color: #495057;background-color: #fff;border: 1px solid #ced4da;border-radius: 0.25rem;}


.custom-file-button input[type="file"] {margin-left: -2px !important;}
.custom-file-button input[type="file"]::-webkit-file-upload-button {display: none;}
.custom-file-button input[type="file"]::file-selector-button {display: none;}
.custom-file-button:hover label {background-color: #dde0e3;cursor: pointer;}


a:not([href]):not([tabindex]) {color: inherit;text-decoration: none;}
.form-control[readonly]{background-color: #e9ecef;opacity: 1;}

.custom-file-label::after {position: absolute;top: 0;right: 0;bottom: 0;z-index: 3;display: block;height: calc(1.5em + 0.75rem);padding: 0.375rem 0.75rem;line-height: 1.5;color: #495057;content: "Browse";background-color: #e9ecef;border-left: inherit;border-radius: 0 0.25rem 0.25rem 0;}

.custom-file-input {position: relative;z-index: 2;width: 100%;height: calc(1.5em + 0.75rem + 2px);margin: 0;opacity: 0;cursor: pointer;}
.btn-theme:active,.btn-theme:focus-visible {
  background-color: var(--primary-color) !important;
}

.footer-map iframe{width: auto !important; height: 150px !important; border: 0 !important; border-radius: 10px;}
.form-check-input:checked{background-color: var(--primary-color);border-color: var(--primary-color);}
input:read-only:focus{border-color:#e9ecef ; accent-color:var(--primary-color);}
thead th{vertical-align: middle !important;}
.select2-mil-main{display: flex; align-items: center; justify-content: space-between; gap: 10px;}
.select2-mil-link-div{width: 100px;display: flex; align-items: center; justify-content: flex-end;gap: 10px;}
.select2-mil-link{font-size: 14px; line-height: normal; font-weight: 500;color: var(--primary-color) !important;}
.page-not-found-section{background-color: #fafbff;display: flex;align-items: center;justify-content: center;position: relative; padding: 70px 0;}

.form-switch .form-check-input:focus { background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba(0, 0, 0, 0.25)'/></svg>"); }
.form-switch .form-check-input:checked{ background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgb(255 255 255)'/></svg>"); }
.select2-selection__choice { background: #DDDDDD; color: #000; }
.btn-close:focus{box-shadow: none;}
